import { save, openWhatsapp, getMeta, isDesktop, getMessage } from '../store.js';

(($) => {

    async function handleFormSubmission(e, formData) {

        const formId = formData.get('form_id');
        const fields = {}
        // Print all entries
        for (const pair of formData.entries()) {
            fields[pair[0]] = pair[1]
        }

        const widget = document.querySelector('[data-whatsapp]');

        if (widget ) {

            // Inside widget.
            const widgetId = widget.dataset.widget;
            if (!widgetId) return;
 
            const widgetObject = formychat_vars.data.widgets.find(widget => widget.id == widgetId);
            if ( !widgetObject ) return;

            // Notify parent.
            window.parent.postMessage({ formychat_submitted: true }, '*');

            const whatsappNumber = widget.dataset.whatsapp;
            const { new_tab, web_version, forminator_message_template } = widgetObject.config.whatsapp || {};
            const message = getMessage(forminator_message_template || form.response.data.result.formychat.forminator_message_template || '', fields);
            const data = {
                form: 'forminator',
                form_id: formId,
                field: fields,
                meta: getMeta.value,
            }
            
            save(data, whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);
            openWhatsapp(whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);

        } else {
            console.log('No widget found');
            return;
            
            const settings = form.response.data.result.formychat;
            if ( !settings || settings.status != 1 ) return;
            const { whatsapp_number, fluentform_message_template, new_tab, web_version } = settings;
            const message = getMessage(fluentform_message_template, fields);
            openWhatsapp(whatsapp_number, message, isDesktop() && web_version == 1, new_tab == 1);
        }
    }

    $(document).on('forminator:form:submit:success', handleFormSubmission)

})(jQuery)
