import { save, openWhatsapp, getMeta, isDesktop, getMessage } from '../store.js';
import axios from 'axios';

(function ($) {
    $(document).ready(function () {

        $(document).on('gform_confirmation_loaded', async function (event, formId) {
            const rest = await axios.get(formychat_vars.ajax_url + '?action=formychat_get_gf_entry&id=' + formId + '&nonce=' + formychat_vars.nonce);
            const settings = rest.data.data.formychat || {};

            const widget = document.querySelector('[data-whatsapp]');

            let data = {
                form: 'gravity_forms',
                form_id: formId,
                field: settings.values,
                meta: getMeta.value,
            }


            if (widget ) {
                // Inside widget.
                const widgetId = widget.dataset.widget;
                if (!widgetId) return;

                const widgetObject = formychat_vars.data.widgets.find(widget => widget.id == widgetId);
                if ( !widgetObject ) return;
                
                window.parent && window.parent.postMessage({ formychat_submitted: true }, '*');

                const whatsappNumber = widget.dataset.whatsapp;
                const { new_tab, web_version, gravity_message_template } = widgetObject.config.whatsapp || {};
                const message = getMessage(gravity_message_template || settings.message, settings.values);

                save(data, whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);
                openWhatsapp(whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);

            } else {
                // Outside widget.
                
                
                // if ( ! settings.status ) return;
                // let message = getMessage(settings.message, inputs);
                // console.log('message', message);
                // return;
                
                // save(data, settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
                // openWhatsapp(settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
            }
        });


        window.gform_formychat = function (form, entries = {}, settings) {
            
            // Object to store label-value pairs
            let values = {};
        
            // Loop through fields in the form
            form.fields && form.fields.forEach(field => {
                let label = field.label; // Get the label of the field
                let value;
        
                // Check if the field has multiple inputs (e.g., name fields with prefix, first, last, etc.)
                if (field.inputs && Array.isArray(field.inputs)) {
                    // Concatenate values for multi-input fields
                    value = field.inputs.map(input => {
                        const inputValue = entries[input.id] || ''; // Get the entry value for each sub-field
                        return inputValue.trim(); // Trim spaces to avoid unnecessary gaps
                    }).filter(v => v).join(' '); // Join non-empty values with a space
                } else {
                    // For single-input fields, get the value directly
                    value = entries[field.id] || '';
                }
        
                // Add the label-value pair to the values object
                if (label && value) {
                    values[label] = value;
                }
            });
        
            // Save inputs.
            let data = {
                form: 'gravity_forms',
                form_id: form.id,
                field: values,
                meta: getMeta.value,
            }

            let message = getMessage(settings.message, values);
            console.log('message', data, settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
        
            save(data, settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
            openWhatsapp(settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
        };
        
        // Trigger new custom event formychat_loaded
        const event = new CustomEvent('formychat_loaded', {
            detail: { }
        });

        document.dispatchEvent(event);

    });

})(jQuery)