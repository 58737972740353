import { save, openWhatsapp, getMeta, isDesktop, getMessage } from './../store.js';

(function ($) {
    $(document).ready(function () {
        /** Catch CF7 Form */
        const handleFormSubmission = async (e) => {

            // Bail if not successful
            if (e.detail.apiResponse.status !== 'mail_sent') return;

            const settings = e.detail.apiResponse.formychat || {}

            // Collect lead data
            const inputs = Object.fromEntries(e.detail.inputs.map(input => [input.name, input.value])) || {};
            
            // Closest .formychat-widget-custom-form of the e.target
            const form = e.target;
            const widget = form.closest('[data-widget]');
            
            const data = {
                form: 'cf7',
                form_id: e.detail.contactFormId,
                field: inputs,
                meta: getMeta.value,
            }

            if ( widget ) {
                // Inside widget.
                const widgetId = widget.dataset.widget || null;
                if (!widgetId) return;
    
                const widgetObject = formychat_vars.data.widgets.find(widget => widget.id == widgetId);
                if ( !widgetObject ) return;

                // Notify parent.
                window.parent.postMessage({ formychat_submitted: true }, '*');

                const whatsappNumber = widget.dataset.whatsapp;
                const { new_tab, web_version, cf7_message_template } = widgetObject.config.whatsapp || {};
                const message = getMessage(cf7_message_template || settings.message, inputs);
                
                save(data, whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);
                openWhatsapp(whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);
                
            } else {
                // Outside widget.
                if ( 'on' !== settings.status ) return;
                let message = getMessage(settings.message, inputs, /\[(.*?)\]/);

                save(data, settings.whatsapp_number, message, isDesktop(), 'on' == settings.new_tab);
                openWhatsapp(settings.whatsapp_number, message, isDesktop(), 'on' == settings.new_tab);
            }
        }

        document.addEventListener('wpcf7submit', handleFormSubmission);

    });

})(jQuery)