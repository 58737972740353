<script setup>
import { ref, defineEmits, computed, onMounted } from 'vue';
import Text from './Text.vue';
import ProBadge from './ProBadge.vue';

const props = defineProps({
    options: {
        type: Object,
        default: []
    },
    modelValue: {
        type: [String, Number],
        default: ''
    },
    placeholder: {
        type: String,
        default: 'Select an option'
    },
    search: {
        type: String,
        default: 'Search..',
    },
    not_found: {
        type: String,
        default: 'No results found',
    },
    cursor: {
        type: Boolean,
        default: true,
    },
    direction : {
        type: String,
        default: 'down',
    },
    white: {
        type: Boolean,
        default: false,
    },
    excerpt: {
        type: Number,
        default: 50,
    },
    name: {
        type: String,
        default: '',
    },
    default: {
        type: String,
        default: '',
    },
});

const hovered = ref(props.modelValue || '');

const emit = defineEmits(['update:modelValue', 'select']);

const select = (key, locked) => {
    if ( locked ) {
        close();
        return;
    }
    props.modelValue = key;
    hovered.value = key;
    emit('update:modelValue', key);
    emit('select', key);
    close();
};


const excerpt =  (text, delimiter = '...') => {
    return text.length > props.excerpt ? text.substring(0, props.excerpt) + delimiter : text;
}
const isOpen = ref(false);

const search = ref('');
const filteredOptions = computed(() => {

    const options = props.options.map(option => {
        return {
            ...option,
            name: option.name || option.label, // Excerpt it.
            label: option.label ? excerpt(option.label) : null,
            locked: option.locked || false,
        };
    });

    // Filter options based on search
    const s = search.value.toLowerCase()
    if (!s) return options;

    // Search in value, name, label options array.
    return options.filter(option => {
        return option.value.toLowerCase().includes(s) || option.label.toLowerCase().includes(s) || option.name.toLowerCase().includes(s);
    });
});


const placeholder = computed(() => {
    let _pl = props.options.find(option => {
        return option.value === props.modelValue;
    })?.name || props.placeholder;

    // Return only first 20 characters if length is greater than 20
    return excerpt(_pl);
});

const dropdown = ref(null);

const open = () => {
    isOpen.value = true;
    setTimeout(() => {
        // Focus on search input
        const searchInput = dropdown.value.querySelector('input');
        if (searchInput) searchInput.focus();

        // Scroll dropdown to selected element
        const selectedElement = dropdown.value.querySelector(`[data-value="${props.modelValue}"]`);
        if (selectedElement) {
            selectedElement.scrollIntoView({
                block: 'nearest',
                inline: 'nearest',
                
            });
        }
    }, 50);
};

const close = () => {
    isOpen.value = false;
    search.value = '';
};

const switchTo = (to = 'prev') => {
    if (!props.options || props.options.length === 0) return; // Guard clause for empty options

    const keys = props.options.map(option => option.value);
    const currentIndex = keys.indexOf(hovered.value);
    let nextIndex = to === 'prev' ? currentIndex - 1 : currentIndex + 1;

    if (nextIndex < 0) nextIndex = keys.length - 1;
    if (nextIndex >= keys.length) nextIndex = 0;

    // If locked, skip to next
    if (props.options[nextIndex].locked) {
        switchTo(to);
        return;
    }

    hovered.value = keys[nextIndex];

    // Scroll to hovered element
    const hoveredElement = dropdown.value.querySelector(`[data-value="${hovered.value}"]`);
    if (hoveredElement) {
        hoveredElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }
};


// Anywhere  clicked but dropdown, close the dropdown
const selectbox = ref(null)
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('click', (e) => {
            if (selectbox.value && !selectbox.value.contains(e.target)) {
                close();
            }
        });
    }, 50)

    if (props.default) {
        props.modelValue = props.default;
    }

})
</script>
<template>
    <div class="relative outline-none" ref="selectbox" @keypress.enter="!isOpen ? open() : null"
    :class="{ 'z-20' : isOpen }"
        @keypress.space="!isOpen ? open() : null" tabindex="0">
        <div v-bind="$attrs" @click="isOpen ? close() : open()"
        tabindex="1"
            class="h-11 flex focus:border-[2px] w-fit max-w-64 focus:border-indigo-400 items-center justify-center gap-2 px-4 border rounded  border-slate-200 cursor-pointer hover:bg-white transition" :class="{'hover:shadow bg-gray-50' : !props.white, 'bg-white' : props.white}">
            <div class="flex items-center gap-2 w-full whitespace-nowrap" v-html="placeholder"></div>
            <svg v-if="!!props.cursor" xmlns="http://www.w3.org/2000/svg" class="fill-current w-4" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
            </svg>
        </div>
        <transition :name="props.direction === 'up' ? 'form_slide' : 'slide'">
            <div v-if="isOpen">
                <div ref="dropdown" @keydown.esc="close" @keydown.up.prevent="switchTo('prev')"
                    @keydown.down.prevent="switchTo('next')" @keydown.enter.prevent="isOpen ? select(hovered) : open"
                    tabindex="0"
                    class="absolute w-fit max-w-64 text-sm max-h-72 overflow-y-scroll scrollbar-thin rounded translate-y-1 scrollbar-track-slate-50 scrollbar-thumb-indigo-500 bg-white shadow-lg flex flex-col rounded-b-sm overflow-hidden border border-slate-200 z-40"
                    :class="{'bottom-full' : props.direction !== 'down', 'top-full' : props.direction === 'down'}">
                    
                    <div class="sticky top-0">
                        <Text v-model="search" v-if="props.search" :placeholder="props.search" type="search"
                        class="text-sm mb-0.5 h-[40px] rounded-none" />
                    </div>

                    <div v-if="filteredOptions.length" v-for="option in filteredOptions" :key="option.value"
                        :data-value="option.value" @click="select(option.value, option.locked)"
                        class="first:mt-11 py-2.5 px-5 mb-px cursor-pointer transition outline-none border-b border-gray-50 flex items-center justify-between gap-2 child:whitespace-nowrap"
                        :class="{ 
                            'bg-gray-50 text-indigo-600': hovered === option.value, 
                            'hover:bg-gray-50 text-slate-600': hovered !== option.value && !option.locked,
                            'bg-gray-100 -z-10 formychat-locked' : option.locked
                        }">
                        <div class="flex items-center gap-3" v-html="option.label" :class="{ 'opacity-100' : option.locked }"></div>
                        <transition name="bounce">
                            <svg v-if="hovered == option.value" xmlns="http://www.w3.org/2000/svg"
                                class="w-4 fill-indigo-500 text-white" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </transition>
                        <ProBadge text="" v-if="option.locked" />
                    </div>
                    <div v-else>
                        <div class="py-2.5 px-5 text-sm text-slate-500">{{ not_found }}</div>
                    </div>
                </div>
            </div>
        </transition>

        <!-- value  -->
        <input type="hidden" :name="props.name" :value="props.modelValue" />
    </div>
</template>
