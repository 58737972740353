import { save, openWhatsapp, getMeta, isDesktop, getMessage } from '../store.js';

(($) => {
    // Each wpforms.
    async function handleFormSubmission(event, json) {
        const form = event.target;
        const formId = form.dataset.formid;
        const settings = json.data.formychat || {}

        // Closest .formychat-widget-custom-form of the e.target
        const widget = form.closest('[data-whatsapp]') || null;
        const inputs = Object.fromEntries(settings.fields.map(input => [input.name, input.value]))
    
        
        // Save inputs.
        let data = {
            form: 'wpforms',
            form_id: formId,
            field: Object.fromEntries(settings.fields.map(input => [input.name, input.value])),
            meta: getMeta.value,
        }

        if ( widget ) {
            // Inside widget.
            const widgetId = widget.dataset.widget;
            
            if (!widgetId) return;

            const widgetObject = formychat_vars.data.widgets.find(widget => widget.id == widgetId);
            if ( !widgetObject ) return;

            const { new_tab, web_version, wpforms_message_template } = widgetObject.config.whatsapp || {};
            const message = getMessage(wpforms_message_template || settings.message, inputs);
            const whatsappNumber = widget.dataset.whatsapp;

            save(data, whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);
            openWhatsapp(whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);
        } else {
            
            if ( settings.status != 1 ) return;
            let message = getMessage(settings.message, inputs);
            
            save(data, settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
            openWhatsapp(settings.whatsapp_number, message, isDesktop(), 1 == settings.new_tab);
        }
    }

    $(document).on('wpformsAjaxSubmitSuccess', handleFormSubmission);
})(jQuery)