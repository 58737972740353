import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

export const isLocked = !formychat_vars.is_premium

export const countries = formychat_vars.data.countries.sort((a, b) => a.name.localeCompare(b.name)).map(country => {
    return {
        value: country.code,
        label: `<span>${country.flag} (${country.code})</span> ${country.name}`,
        name: `<span>${country.flag}</span> ${country.code}`
    }
})

export const headingSizes = { small: '16px', medium: '18px', large: '20px' }
export const textSizes = { small: '12px', medium: '14px', large: '16px' }
export const iconSizes = { small: '45px', medium: '60px', large: '80px' }

export const getSize = (size, size_custom, options = textSizes) => {
    return options[size] || (isLocked ? options.medium : (size_custom + 'px'));
}

export const isDesktop = () => {
   // Check if it's a mobile device. Do not check screen size because this can be inside a iframe too.
    return !navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
}

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export const openWhatsapp = (to, text = '', isWeb = false, newTab = false) => {
    let url = `https://wa.me/${to}?text=${encodeURIComponent(text)}`;

    // For web version.
    if (isWeb && isDesktop() && !isLocked) url = `https://web.whatsapp.com/send?phone=${to}&text=${encodeURIComponent(text)}`;
    
    if (newTab && !isSafari()) {
        try {
            // Create the new window/tab immediately
            const newWindow = window.open('about:blank', '_blank');
            
            // If popup was blocked, fallback to _top
            if (newWindow === null) {
                window.open(url, '_top');
                return;
            }

            // Set proper window features for security
            if (newWindow) {
                newWindow.opener = null;
                newWindow.location.href = url;
            }
        } catch (e) {
            // Fallback to _top if any error occurs
            window.open(url, '_top');
        }
    } else {
        window.open(url, '_top');
    }
}

/**
 * Save lead to the database.
 */

export const save = async (data, whatsapp = null, message = null, isDesktop = null, newTab = null) => {

    // If whatsapp, message, isDesktop, newTab provided. Add them to data.meta
    if (whatsapp) {
        data.meta.whatsapp = whatsapp
        data.meta.message = message
        data.meta.isDesktop = isDesktop
        data.meta.newTab = newTab
    }

    // console.log('Saving lead', data);
    return new Promise(async (resolve) => {
        axios.post(formychat_vars.rest_url + '/submit-form', data, {
            headers: {
                'X-WP-Nonce': formychat_vars.rest_nonce,
            }
        }).then(response => {
            // console.log('Lead saved', response.data);
            resolve(response.data)
        })
            .catch(error => {
                // console.error('Error saving lead', error);
                resolve(error)
            })
    })
}


/**
 * Meta data.
 */
export const meta = ref({
    Device: isDesktop() ? 'Desktop' : 'Mobile',
    Browser: navigator.appCodeName || 'Unknown Browser',
    Platform: navigator.platform || 'Unknown Platform',
    OS: navigator.oscpu || 'Unknown OS',
    Screen: `${window.screen?.width || 0}x${window.screen?.height || 0}`,
    Language: navigator.language || 'en',
    Vendor: navigator.vendor || 'Unknown Vendor',
    URL: window.parent.location.href || window.location.href || 'Unknown URL',
    Referrer: document.referrer || 'Direct',
})

export const getMeta = computed(() => {

    // Add IP data if available.
    if (window.formychat_ip) {
        meta.value = Object.assign({}, meta.value, {
            Country_Code: window.formychat_ip.country_code || 'US',
            Country: (window.formychat_ip.country + ` <img src="${window.formychat_ip.country_flag}" />`) || 'Unknown Country',
            City: window.formychat_ip.city || 'Unknown City',
            Region: window.formychat_ip.region || 'Unknown Region',
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
            Timezone_GMT: window.formychat_ip.timezone_gmt || '+00:00',
            Currency: window.formychat_ip.currency || 'USD',
            IP: window.formychat_ip.ip || '0.0.0.0',
            Latitude: window.formychat_ip.latitude || 0,
            Longitude: window.formychat_ip.longitude || 0,
            ISP: window.formychat_ip.isp || 'Unknown ISP',
            Organization: window.formychat_ip.org || 'Unknown Organization',
        })
    }

    // Add user data if available.
    if ( formychat_vars.user && formychat_vars.user.id ) {
        meta.value = Object.assign({}, meta.value, {
            User_ID: formychat_vars.user.id || 0,
            User_First_Name: formychat_vars.user.first_name || '',
            User_Last_Name: formychat_vars.user.last_name || '',
            User_Email: formychat_vars.user.email || '',
            User_Phone: formychat_vars.user.phone || '',
        })
    }

    return meta.value
})

export const getGravityForm = (html) => {
    html = new DOMParser().parseFromString(html, 'text/html')

    let form = html.querySelector('form')

    if (form) {

        // change action
        form.setAttribute('action', 'javascript:void(0)')

        // Remove onClick from submit button.
        let submitButton = html.querySelector('input[type="submit"]')

        if (submitButton) {

            submitButton.removeAttribute('onClick')
            submitButton.removeAttribute('onKeyPress')
        }
    }

    return html.body
}


export const getData = ( data = {} ) => {
    // Merge data with formychat_vars.custom_tags
    return Object.assign({}, data, formychat_vars.custom_tags, {
        page_url: window.parent.location.href || window.location.href,
    })
}

export const getMessage = ( text, data = {}, pattern = /{(.*?)}/ ) => {

    data = getData(data) 
    
    while (pattern.test(text)) {
        text = text.replace(pattern, (match, group) => {
            return data[group] || '';
        });
    }

    return text
}