import { save, openWhatsapp, getMeta, isDesktop, getMessage } from '../store.js';

(($) => {

    async function handleFormSubmission(e, form) {

        const entry = form.response.data.result.last_entry || {};
        
        var fields = {}
        Object.entries(entry).filter(([key]) => !key.startsWith('_')).forEach(([key, value]) => {
            if ( typeof value === 'object' ) {
                // Merge fields with same key.
                Object.entries(value).forEach(([key, value]) => {
                    fields[key] = value;
                });
            } else {
                fields[key] = value;
            }
        });

        const widget = document.querySelector('[data-whatsapp]');
        const formId = e.target.dataset['form_id'];

        if (widget ) {
            // Inside widget.
            const widgetId = widget.dataset.widget;
            if (!widgetId) return;
 
            const widgetObject = formychat_vars.data.widgets.find(widget => widget.id == widgetId);
            if ( !widgetObject ) return;

            // Notify parent.
            window.parent.postMessage({ formychat_submitted: true }, '*');

            const whatsappNumber = widget.dataset.whatsapp;
            const { new_tab, web_version, fluentform_message_template } = widgetObject.config.whatsapp || {};
            const message = getMessage(fluentform_message_template || form.response.data.result.formychat.fluentform_message_template || '', fields);
            const data = {
                form: 'fluentform',
                form_id: formId,
                field: fields,
                meta: getMeta.value,
            }
            
            save(data, whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);
            openWhatsapp(whatsappNumber, message, isDesktop() && web_version == 1, new_tab == 1);

        } else {
            const settings = form.response.data.result.formychat;
            if ( !settings || settings.status != 1 ) return;
            const { whatsapp_number, fluentform_message_template, new_tab, web_version } = settings;
            const message = getMessage(fluentform_message_template, fields);
            openWhatsapp(whatsapp_number, message, isDesktop() && web_version == 1, new_tab == 1);
        }
    }

    $('.fluentform form').on('fluentform_submission_success', handleFormSubmission)

})(jQuery)
